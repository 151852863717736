import logo from './logo.svg';
import './App.css';
import { getWhitelist, getGTDWhitelist } from './whitelist';
import { useEffect, useState } from 'react';

function App() {

  const [submitted, setSubmitted] = useState(false);
  const [wallet, setWallet] = useState('');
  const [whitelisted, setWhitelisted] = useState(null);

  const isWhitelisted = async () => {

    const wl = await getWhitelist();
    const gtdWl = await getGTDWhitelist();

    if (wl.indexOf(wallet) > -1 || gtdWl.indexOf(wallet) > -1 ) {

      const type = wl.indexOf(wallet) > -1 && gtdWl.indexOf(wallet) > -1? 'ALL' : wl.indexOf(wallet) > -1? 'FCFS' : 'GTD'; 

      setWhitelisted(type);
      
    } else {
      
      setWhitelisted(false);

    }

  }

  const submit = () => {
    setSubmitted(true);
  }

  const handleWallet = (e) => {
    setWallet(e.target.value.replaceAll(' ', '').toLocaleLowerCase());
  }

  useEffect(() => {

    if ( submitted && wallet.length > 0 && wallet.indexOf(`bc1`) === 0 ) {

      isWhitelisted();
      setSubmitted(false);

    } else {

      if ( submitted ) {

        alert(`❌ Please provide a Bitcoin taproot address! (starts with 'bc1') ❌`)
        setSubmitted(false);

      }

    }

  }, [submitted, wallet]);

  useEffect(() => {

    if (whitelisted) {

      const wlTypeMsg = whitelisted == 'FCFS'? '3 SPOTS on FCFS PHASE WL' : whitelisted == 'ALL'? '1 SPOT on GTD PHASE WL + 3 SPOTS on FCFS PHASE WL' : '1 SPOT on GTD PHASE WL';

      alert('✨ You have '+wlTypeMsg+' of Wizard Meow\'s Magic Pass mint! ✨');

    } else {

      if ( whitelisted === false ) {
        
        alert('❌ You\'re not whitelisted to mint. :C\nDo steps below and try again later! 🫡🧡');
        
      }      
      
    }

    setWhitelisted(null);

  }, [whitelisted]);

  return (
    <div className="App">
      <div className="App-box">
        <span>
          <p><span>✨ 10/31 - Wizard Meow's Magic Pass 🪄✨🌕<br /><br />✨ Like, repost, drop wallet on </span><a target='_blank' href='https://x.com/WizardMeowBTC/status/1848233166839960066'>this tweet</a><span> & follow </span><a target='_blank' href='https://x.com/WizardMeowBTC'>@WizardMeowBTC</a>
            <span><br /><br />✨ Wallets are added to WL manually, please wait some hours to verify your WL spots! 🧡</span>
            <span><br /><br />✨ Mint </span><a target='_blank' href='https://gamma.io/ordinals/collections/ordinals-galaxy'>Ordinals Galaxy 🌌</a><span> to get 1 SPOT on GTD phase! (~0.000222 BTC LESS THAN FCFS phase) and claim OG Discord server role 🧡</span></p>
        </span>
        <textarea value={wallet} onChange={e => handleWallet(e)} className='inputWallet' placeholder='Drop address to verify WL 🪄✨🌕' />
        <input onClick={submit} type='submit' placeholder='Submit' />
      </div>
    </div>
  );
}

export default App;
