const getWhitelist = async () => {
    const r = await fetch(`/whitelist.txt`);
    if ( r.status === 200 ) {
        let wl = await r.text();
        wl = wl.replaceAll('\r', '');
        wl = wl.replaceAll(' ', '');
        wl = wl.toLocaleLowerCase();
        return wl.split(`\n`);
    } else {
        return [];
    }
};

const getGTDWhitelist = async () => {
    const r = await fetch(`/gtd_whitelist.txt`);
    if ( r.status === 200 ) {
        let wl = await r.text();
        wl = wl.replaceAll('\r', '');
        wl = wl.replaceAll(' ', '');
        wl = wl.toLocaleLowerCase();
        return wl.split(`\n`);
    } else {
        return [];
    }
};

module.exports.getWhitelist = getWhitelist
module.exports.getGTDWhitelist = getGTDWhitelist